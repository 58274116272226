<template>
<v-app>
    <div>
        <TheSidebar v-if="shouldShowSidebar" />
    </div>
    <TheHeader />
 
    <router-view :key="$route.path"></router-view>

</v-app>
</template>

<script>
import TheHeader from './TheHeader'
import TheSidebar from './TheSidebar'

export default {
    name: 'TheContainer',
    components: {
        TheHeader,
        TheSidebar
    },
    created() {
        this.pocSupport(document, "script");
    },
    computed: {
        shouldShowSidebar() {
            return this.$route.meta.sidebar !== false
        }
    },
    methods: {
        pocSupport(d, t) {
            var BASE_URL = "https://poc-support.tlic.cmu.ac.th";
            var g = d.createElement(t),
                s = d.getElementsByTagName(t)[0];
            g.src = BASE_URL + "/packs/js/sdk.js";
            g.defer = true;
            g.async = true;
            s.parentNode.insertBefore(g, s);
            g.onload = function () {
                window.chatwootSDK.run({
                    websiteToken: 'gXyCygE9uRDDgFHFTsrPmzkY',
                    baseUrl: BASE_URL
                })
            }
        }

    }
}
</script>
